import React from 'react';
import hempImage from './assets/BackgroundImgNew.jpg';  
import "./comingsoon.css";
import logoImage from './assets/Chill Leaf CollectiveLogo.png';

const ComingSoonPage: React.FC = () => {
  return (
    <div className="coming-soon-container">
      <div className="background-image" style={{ backgroundImage: `url(${hempImage})` }} />
      <div className="content">
      <img src={logoImage} alt="Company Logo" className="company-logo" />
        <h1> A Fresh Look and Exciting Features Are <span>Coming Soon!</span></h1>
        
        <p>We are busy crafting something special for you. Stay tuned for the whole new online experience.</p>
        
        <div className="countdown">
{/*           <p>Reveal Date: <strong>June 2024</strong></p> */}
        </div>
      </div>
    </div>
  );
}

export default ComingSoonPage;


