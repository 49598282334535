
import './App.css';
import Comingsoon from "./Comingsoon";
function App() {
  return (
    <div className="App">
      <Comingsoon></Comingsoon>
    </div>
  );
}

export default App;
